import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { supabase } from 'utils/supabase'
import { Button } from '@mui/material'
import styled from 'styled-components'
import VipPageWrapper from 'admin/components/VipPageWrapper'
import { VipTableRowTypes } from 'utils/vipTableRowTypes'
import VipsTable from 'admin/components/VipsTable'
import { mobileCss } from 'utils/theme'


export interface VipTypes {
  code: string
  id: string
  fullName: string
  vipFullName: string
  vipSortingName: string
}

const VipList = () => {
  
  const [vips, setVips] = useState<VipTableRowTypes[]>([])
  const navigate = useNavigate()
  const { code } = useParams()
  
  const getVips = async () => {
    try {
      const response = await supabase
        .from('vip_links')
        .select('*')
      
      const data = response.data as VipTableRowTypes[]
      
      setVips(data)
      
    } catch (err) {
      console.error(err)
    }
  }
  
  useEffect(() => {
    console.log('Current code:', code)
    if (!code) {
      // If no code, we're in admin view
      getVips()
    } else {
      // Validate code and then get vips
      getVips()
    }
  }, [code])
  
  // @ts-ignore
  return (
    <VipPageWrapper>
      <Header>
        <Title>
          Członkowie PRB
        </Title>
        <Button variant='outlined' onClick={() => navigate(`/${code}/broszura/wszyscy`)}>Zobacz wszystkich</Button>
      </Header>
      <VipsTable
        vips={vips}
        onRemove={getVips}
      />
    </VipPageWrapper>
  )
}

export default VipList

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  ${mobileCss(`
  flex-direction: column,
  margin-bottom: 10px;
  `)};
`

const Title = styled.div`
  font-size: 24px;

  ${mobileCss(`
   width: auto;
   font-size: 18px;
  `)};
`
