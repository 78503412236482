import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { supabase } from 'utils/supabase';
import VipPreview from 'admin/components/VipPreview';
import { mobileCss } from 'utils/theme';
import { VipTableRowTypes } from 'utils/vipTableRowTypes';

const VipBookletPreview = () => {
  const [vips, setVips] = useState<VipTableRowTypes[]>([]);

  const getVips = async () => {
    try {
      const { data, error } = await supabase.from('vip_links').select();

      if (error) {
        throw error;
      }

      if (data) {
        // Sort vips by vipSortingName using localeCompare with Polish locale
        const sortedData = data.sort((a: VipTableRowTypes, b: VipTableRowTypes) => {
          return (a.vipSortingName || '').localeCompare(b.vipSortingName || '', 'pl');
        });

        setVips(sortedData as VipTableRowTypes[]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getVips();
  }, []);

  return (
    <PreviewWrapper>
      <div>
        <Header>Członkowie Polskiej Rady Biznesu</Header>
        <Wrapper>
          {vips.map((vip: VipTableRowTypes) => (
            <VipPreview key={vip.id} vip={vip} />
          ))}
        </Wrapper>
      </div>
    </PreviewWrapper>
  );
};

export default VipBookletPreview;

const PreviewWrapper = styled.div`
  padding: 16px;

  ${mobileCss(`
    width: 90%;
  `)};
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
`;

const Header = styled.div`
  margin-bottom: 50px;
  font-size: 34px;
  color: #444;
  text-align: center;

  &:not(:first-of-type) {
    margin-top: 100px;
  }
`;
