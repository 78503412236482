import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { Theme } from 'utils/theme';
import { createBrowserRouter, RouterProvider, useParams } from 'react-router-dom';
import { AppCenterContextProvider } from 'AppContext';
import 'utils/global.css';
import { Toaster } from 'react-hot-toast';
import Cookies from 'components/Cookies';
import Login from 'admin/pages/Login';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.core.css';
import SingleVipPreview from 'admin/pages/SingleVipPreview';
import VipBookletPreview from 'admin/components/VipBookletPreview';
import VipList from 'admin/pages/VipList';
import EditVip from 'admin/pages/EditVip';
import AdminVipList from 'admin/pages/adminPages/AdminVipList';
import AdminEditVip from 'admin/pages/adminPages/AdminEditVip';
import AdminVipPreview from 'admin/pages/adminPages/AdminVipPreview';
import NotFound from 'admin/pages/notFound';

const ValidateCode = ({ component: Component }: { component: React.ComponentType }) => {
  const { code } = useParams();
  const isValid = /^[a-zA-Z0-9]{14}$/.test(code || '');
  
  return isValid ? <Component /> : <NotFound />;
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  // Admin routes (place these first to take precedence)
  {
    path: '/admin',
    element: <Login />
  },
  {
    path: '/admin/menu',
    element: <AdminVipList />
  },
  {
    path: '/admin/menu/podglad/:id',
    element: <AdminVipPreview />
  },
  {
    path: '/admin/menu/edytuj/:id',
    element: <AdminEditVip />
  },
  {
    path: '/admin/menu/broszura/wszyscy',
    element: <VipBookletPreview />
  },

  // VIP routes with validation in component
  {
    path: '/:code',
    element: <ValidateCode component={VipList} />
  },
  {
    path: '/:code/podglad/:id',
    element: <ValidateCode component={SingleVipPreview} />
  },
  {
    path: '/:code/broszura/wszyscy',
    element: <ValidateCode component={VipBookletPreview} />
  },
  {
    path: '/:code/edytuj',
    element: <ValidateCode component={EditVip} />
  },

  // Invalid :code catcher 404
  {
    path: '*',
    element: <NotFound />
  }
]);

root.render(
  <ThemeProvider theme={Theme}>
    <Toaster />
    <AppCenterContextProvider>
      <RouterProvider router={router} />
      <div id='modal-portal' />
      <Cookies />
    </AppCenterContextProvider>
  </ThemeProvider>
);
