import React from 'react';

const NotFound = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>404 - Nie ma takiej strony</h1>
      <p>Strona jakiej szukasz nie istnieje</p>
    </div>
  );
};

export default NotFound;