import React from 'react'
import { VipTableRowTypes } from 'utils/vipTableRowTypes'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { mobileCss } from 'utils/theme'

interface Vips {
  vips: VipTableRowTypes[]
  onLinkClick?: () => void
}

const NamesNavigation = ({ vips, onLinkClick }: Vips) => {
  const navigate = useNavigate()
  const { code } = useParams()
  const isAdmin = window.location.pathname.includes('/admin')
  
  const sortedVips = vips
    .slice()
    .sort((a, b) => ((a.vipSortingName ?? '') > (b.vipSortingName ?? '') ? 1 : -1))
  
  return (
    <>
      <Title>Lista osób:</Title>
      <Wrapper>
        {sortedVips.map(vip => (
          <ListedName 
            key={vip.id} 
            onClick={() => {
              if (isAdmin) {
                navigate(`/admin/menu/podglad/${vip.id}`)
              } else {
                navigate(`/${code}/podglad/${vip.id}`)
              }
              if (onLinkClick) onLinkClick()
            }}
          >
            {vip.vipFullName}
          </ListedName>
        ))}
      </Wrapper>
    </>
  )
}

export default NamesNavigation

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 12px;
  max-height: 450px; /* Adjust the height as needed */
  overflow-y: auto;

  ${mobileCss(`
  max-width: 160px;
  `)};
`

const Title = styled.div`
  margin-top: 25px;
  font-size: 14px;
  margin-bottom: 15px;
  padding-left: 10px;
  font-weight: 700;
`
const ListedName = styled.div`
  padding: 10px;
  border-radius: 8px;
  transition: all .3s;
  text-decoration: none;
  color: #444;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
    cursor: pointer;
  }
`
